import React, { Component, useState, useEffect } from "react";
import Props from "../../Types";
import styled from "styled-components";

import Male from "../../assets/icon_male.png";
import Female from "../../assets/icon_female.png";
import BackgroundImage from "../../assets/bg_rect.png";
import Temperature from "../../assets/icon_user_temperature.png";
import HeartRate from "../../assets/icon_user_heartrate.png";
import BloodPressure from "../../assets/icon_user_bp.png";
import BloodOxygen from "../../assets/icon_user_o2.png";
import BloodGlucose from "../../assets/icon_user_bg.png";
import Arrow from "../../assets/icon_blue_arrow_down.png";
import ECG from "../../assets/icon_ecg.png";
import Area from "../../assets/icon_area.png";
import Stop from "../../assets/icon_stop.png";

interface DeviceDetailRowProps {
  image: any;
  name: string;
  value: string;
  monitorTime: string;
}

const Row = styled.div`
  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(13, 39, 80, 0.298039), -2px -2px 5px #cbd4e2;
  border-radius: 8px;
  margin: 0 8.13px 0 8.13px;
  padding: 0 6px 0 6px;
  display: flex;
  height: 60px;
`;

const KeyBox = styled.div`
  display: flex;
  width: calc(100% - 123px);
`;

const ValueBox = styled.div`
  width: 123px;
  height: 52px;
  border-radius: 5px;
  overflow: hidden;
  margin: auto 0 auto auto;
`;

const Input = styled.input`
  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.698039),
    inset 2px 2px 5px rgba(13, 39, 80, 0.4),
    inset -5px -5px 5px rgba(223, 223, 223, 0.298039);
  width: 100%;
  height: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 10px;

  align-items: center;
  text-align: center;

  color: #3c3c3c;
`;

const DeviceDetailRow = (props: DeviceDetailRowProps) => {
  return (
    <Row>
      <KeyBox>
        <img alt="" className="w-7 h-7 self-center" src={props.image} />
        <div className="details-title">{props.name}</div>
      </KeyBox>
      <ValueBox>
        <div className="details-datetime">{props.monitorTime}</div>
        <Input value={props.value} />
      </ValueBox>
    </Row>
  );
};

export default DeviceDetailRow;
