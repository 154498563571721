import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  Ref,
} from "react";
import Props, { Device, User, ATGPagingResult, HealthAlert } from "../../Types";
import styled from "styled-components";
import DeviceGrid from "./DeviceGrid";
import DeviceImage from "../../assets/icon_device.png";
import Plus from "../../assets/icon_plus.png";
import Minus from "../../assets/icon_minus.png";
import { useTranslation } from "react-i18next";
import DeviceRow from "./DeviceRow";
import AlertRow from "./AlertRow";

import Warning from "../../assets/icon_warning.png";

import AuthorizationHub from "../../plugins/AuthorizationHub";
import IoTHub from "../../plugins/IoT-Hub";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

const LandingStyledBox = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  background: #ececec;
`;

const DeviceListBox = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 23px;

  min-height: 54px;

  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(13, 39, 80, 0.2), -5px -5px 5px #cbd4e2;
  border-radius: 10px;
  overflow: hidden;
`;

const NoticeBox = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 18.2px;

  min-height: 54px;

  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(13, 39, 80, 0.2), -5px -5px 5px #cbd4e2;
  border-radius: 10px;
  overflow: hidden;
`;

const Message = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #3c3c3c;
`;

const CenterBox = styled.div`
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
`;

export interface RefObject {
  search: (keyword: string) => void;
  clean: () => void;
  clearUsers: () => void;
}

const Landing = forwardRef((props, ref: Ref<RefObject>) => {
  const { t } = useTranslation();
  const [deviceListIsShowed, setDeviceListIsShowed] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [users, setUsers] = useState<[User]>();
  const [alerts, setAlerts] = useState<HealthAlert[]>();
  const [currentUserPage, setCurrentUserPage] = useState(1);
  const [devices, setDevices] = useState<Device[]>();

  useImperativeHandle(ref, () => ({
    search(keyword: string) {
      searchBy(keyword);
    },
    clean() {
      refresh();
    },
    clearUsers() {
      setUsers(undefined);
    },
  }));

  function searchBy(keyword: string) {
    setIsLoadingUsers(true);
    AuthorizationHub.getInstance()
      .requestAuthorization()
      .then(function () {
        setCurrentUserPage(1);
        IoTHub.getInstance()
          .listUser({ page: currentUserPage, pageSize: 200, name: keyword })
          .then(function (result) {
            console.log(result);
            setIsLoadingUsers(false);
            let pagingResult = result as ATGPagingResult;
            let users = pagingResult.result;
            setUsers(users);
          })
          .catch(function (err) {
            setIsLoadingUsers(false);
            console.log(err);
          });
      });
  }

  function triggerDeviceList() {
    if (!deviceListIsShowed) {
      getDevices();
    }
    setDeviceListIsShowed(!deviceListIsShowed);
  }

  const refresh = () => {
    setIsLoadingUsers(true);
    AuthorizationHub.getInstance()
      .requestAuthorization()
      .then(function () {
        setCurrentUserPage(1);
        IoTHub.getInstance()
          .listUser({ page: currentUserPage, pageSize: 200 })
          .then(function (result) {
            setIsLoadingUsers(false);
            console.log(result);
            let pagingResult = result as ATGPagingResult;
            let users = pagingResult.result;
            setUsers(users);
          })
          .catch(function (err) {
            setIsLoadingUsers(false);
            console.log(err);
          });
      });
  };

  const getDevices = () => {
    setDevices(undefined);
    setIsLoadingDevices(true);
    IoTHub.getInstance()
      .listAllDevice(1, 200)
      .then(function (result) {
        setIsLoadingDevices(false);
        let pagingResult = result as ATGPagingResult;
        let devices = pagingResult.result;
        setDevices(devices);
      })
      .catch(function (err) {
        setIsLoadingDevices(false);
        console.log(err);
      });
  };

  const updateStatus = (id: string) => {
    if (devices === undefined) return;
    const index = devices.findIndex((device) => device.id === id);
    const device = devices[index];
    if (device === undefined) return;
    if (device.enable) {
      IoTHub.getInstance()
        .disableDevice(id)
        .then(function () {
          device.enable = false;
          const newRecords: Device[] = [...devices];
          newRecords[index] = device;
          setDevices(newRecords);
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      IoTHub.getInstance()
        .enableDevice(id)
        .then(function () {
          device.enable = true;
          const newRecords: Device[] = [...devices];
          newRecords[index] = device;
          setDevices(newRecords);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  };

  const dismissAlertBy = (id: string) => {
    IoTHub.getInstance()
      .dismissAlert(id)
      .then(function () {})
      .catch(function (err) {
        console.log(err);
      });
  };

  const getAlerts = () => {
    IoTHub.getInstance()
      .listAlert()
      .then(function (result) {
        let pagingResult = result as ATGPagingResult;
        let list = pagingResult.result;
        setAlerts(list);
        setTimeout(() => getAlerts(), 1000);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    refresh();
    getAlerts();
  }, []);

  const override = css`
    display: block;
    position: absolute;
  `;

  const overrideDevice = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <LandingStyledBox>
      <div className="w-3/4 min-h-screen h-screen overflow-scroll pb-24 relative">
        {isLoadingUsers && (
          <>
            <div
              className="w-full h-full absolute left-0 top-0 z-50"
              style={{ backgroundColor: "rgb(0,0,0,0.3)" }}
            >
              <CenterBox className="relative">
                <ClipLoader
                  color="#36D7B7"
                  css={override}
                  loading={isLoadingUsers}
                  size={150}
                />
              </CenterBox>
            </div>
          </>
        )}
        {users !== undefined &&
          users.map((user, index) => {
            return (
              <>
                <div className="float-left py-4 px-5">
                  <DeviceGrid
                    index={index}
                    userId={user.id}
                    userName={user.name}
                    userGender={user.gender}
                  />
                </div>
              </>
            );
          })}
      </div>
      <div className="w-1/4 h-full">
        <DeviceListBox>
          <div className="flex">
            <img alt="" className="w-10 h-10 mt-1.5 ml-6" src={DeviceImage} />
            <p className="ml-4 self-center w-1/2">{t("equipment.title")}</p>
            <div
              className="self-center ml-auto right-7 relative"
              onClick={() => {
                triggerDeviceList();
              }}
            >
              <img alt="" src={!deviceListIsShowed ? Plus : Minus} />
            </div>
          </div>
          {isLoadingDevices && (
            <>
              <div className="mt-7 w-full flex">
                <ClipLoader
                  color="#36D7B7"
                  css={overrideDevice}
                  loading={isLoadingDevices}
                  size={35}
                />
              </div>
            </>
          )}
          {deviceListIsShowed && (
            <>
              <div className="mt-7 pt-1 pl-5 pb-5 pr-4 bg-white max-h-52 overflow-scroll space-y-1">
                {devices !== undefined && (
                  <>
                    {devices.map((item, index) => {
                      return (
                        <DeviceRow
                          device={item}
                          onClick={() => {
                            updateStatus(item.id);
                          }}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            </>
          )}
        </DeviceListBox>
        <NoticeBox>
          <div className="flex">
            <img alt="" className="w-10 h-10 mt-1.5 ml-6" src={Warning} />
            <p className="ml-4 self-center w-1/2">{t("abnormal.title")}</p>
          </div>
          <Message className="ml-6 my-2">
            {t("abnormal.current", { quantity: alerts?.length || 0 })}
          </Message>
          {alerts !== undefined && (
            <>
              <div className="space-y-2 mb-4 px-5 max-h-64 overflow-scroll ">
                {alerts.map((item, index) => {
                  return (
                    <AlertRow
                      alert={item}
                      dismissAlert={() => {
                        dismissAlertBy(item.id);
                      }}
                    />
                  );
                })}
              </div>
            </>
          )}
        </NoticeBox>
      </div>
    </LandingStyledBox>
  );
});

export default Landing;
