import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
import NavigationBar from "./components/Navigation/NavigationBar";
import Monitor, { RefObject } from "./components/Monitor/Landing";
import AuthorizationHub from "./plugins/AuthorizationHub";
import Login from "./components/Login";

function App() {
  const [isLoggedOn, setIsLoggedOn] = useState(false);
  const montiorRef = useRef<RefObject>(null);

  useEffect(() => {
    AuthorizationHub.getInstance()
      .requestAuthorization()
      .then(function () {
        try {
          setTimeout(() => setIsLoggedOn(true), 500);
        } catch (error) {
          console.log(error);
        }
      })
      .catch(function () {
        setIsLoggedOn(false);
      });
  }, []);

  const login = (username: string, password: string) => {
    AuthorizationHub.getInstance()
      .signIn(username, password)
      .then(function () {
        console.log("true");
        try {
          setTimeout(() => setIsLoggedOn(true), 500);
        } catch (error) {
          console.log(error);
        }
      })
      .catch(function (err) {
        setIsLoggedOn(false);
        console.log(err);
      });
  };

  return (
    <div className="w-full h-full m-0 overflow-hidden cursor-default">
      <NavigationBar
        handleSearch={(keyword) => {
          console.log(montiorRef.current);
          if (montiorRef.current) {
            montiorRef.current.search(keyword);
          }
        }}
        clean={() => {
          montiorRef.current?.clean();
        }}
        signout={() => {
          console.log("called signout");
          setIsLoggedOn(false);
          AuthorizationHub.getInstance().signOut();
        }}
      />
      {isLoggedOn ? (
        <>
          <Monitor ref={montiorRef} />
        </>
      ) : (
        <>
          <div
            className="w-full h-screen"
            style={{ background: "#ececec" }}
          ></div>
          <Login
            onClick={(username, password) => {
              login(username, password);
            }}
          />
        </>
      )}
    </div>
  );
}

export default App;
