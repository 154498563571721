import React, { Component, useState, useEffect } from "react";
import Props, { UserOverrallHealthRecord, ATGResponse } from "../../Types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Male from "../../assets/icon_male.png";
import Female from "../../assets/icon_female.png";
import BackgroundImage from "../../assets/bg_rect.png";
import Temperature from "../../assets/icon_user_temperature.png";
import HeartRate from "../../assets/icon_user_heartrate.png";
import BloodPressureImage from "../../assets/icon_user_bp.png";
import BloodOxygen from "../../assets/icon_user_o2.png";
import BloodGlucose from "../../assets/icon_user_bg.png";
import Arrow from "../../assets/icon_blue_arrow_down.png";
import ECG from "../../assets/icon_ecg.png";
import Area from "../../assets/icon_area.png";
import Stop from "../../assets/icon_stop.png";
import DeviceDetailRow from "./DeviceDetailRow";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

import IoTHub from "../../plugins/IoT-Hub";
import ProfileManager from "../../plugins/ProfileManager";

interface DeviceGridProps {
  index: number;
  userId: string;
  userName: string;
  userGender: string;
}

const DeviceGridDiv = styled.div`
  width: 320px;
  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(13, 39, 80, 0.2), -5px -5px 5px #cbd4e2;
  border-radius: 10px;
  overflow: hidden;
  display: relative;
`;

const DataCard = styled.div`
  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(13, 39, 80, 0.298039), -2px -2px 5px #cbd4e2;
  border-radius: 5px;
  margin: 0 5px 0 5px;
  display: flex;
  height: 40px;
`;

const DataCardAction = styled.div`
  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.698039),
    inset 5px 5px 5px rgba(13, 39, 80, 0.2),
    inset -5px -5px 5px rgba(223, 223, 223, 0.698039);
  border-radius: 5px;
  margin: 0 5px 0 5px;
  height: 40px;
  text-align: center;
  display: flex;
`;

const DataCardButton = styled.div`
  width: 30px;
  height: 30px;
  margin: auto 0 auto 2px;
  display: flex;
`;

const Table = styled.table`
  width: calc(100% - 40px);
  margin: 3px 20px 0 20px;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 6px;
`;

const DataCardInput = styled.input`
  width: calc(100% - 30px);
  border: 0px !important;
  margin: auto 5px auto 2px;
  background: #ffffff;
  box-shadow: inset 2px 2px 5px rgba(13, 39, 80, 0.4),
    inset -5px -5px 5px rgba(223, 223, 223, 0.298039);
  border-radius: 5px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  text-align: center;

  color: #3c3c3c;
`;

const DeviceGrid = (props: DeviceGridProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [record, setRecord] = useState<UserOverrallHealthRecord | undefined>(
    undefined
  );
  const [isLoadingRecord, setIsLoadingRecord] = useState(false);
  const { t, i18n } = useTranslation();

  const getRecord = () => {
    setIsLoadingRecord(true);
    IoTHub.getInstance()
      .overallRecord(props.userId)
      .then(function (response) {
        setIsLoadingRecord(false);
        ProfileManager.getInstance().store(
          props.userId,
          response as UserOverrallHealthRecord
        );
        setRecord(response as UserOverrallHealthRecord);
        setTimeout(() => getRecordOnBackground(), 1000);
      })
      .catch(function (err) {
        setIsLoadingRecord(false);
        console.log(err);
      });
  };

  const getRecordOnBackground = () => {
    IoTHub.getInstance()
      .overallRecord(props.userId)
      .then(function (response) {
        ProfileManager.getInstance().store(
          props.userId,
          response as UserOverrallHealthRecord
        );
        setRecord(response as UserOverrallHealthRecord);
        setTimeout(() => getRecordOnBackground(), 1000);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    getRecord();

    // return () => {
    //   clearInterval();
    // };
  }, []);

  function temperatureTitle() {
    return t("temperature.title") + " (" + t("temperature.unit_c") + ")";
  }

  function heartRateTitle() {
    return t("heartRate.title") + " (" + t("heartRate.unit") + ")";
  }

  function bloodPressureTitle() {
    return t("bloodPressure.title") + " (" + t("bloodPressure.unit") + ")";
  }

  function bloodOxygenTitle() {
    return t("bloodOxygen.title") + " (" + t("bloodOxygen.unit") + ")";
  }

  function bloodGlucoseTitle() {
    return t("bloodGlucose.title") + " (" + t("bloodGlucose.unit") + ")";
  }

  function bloodOxygenLabel() {
    if (record !== undefined && record.bloodOxygen.record != null) {
      return record.bloodOxygen.record.spO2.toString();
    } else {
      return "0";
    }
  }

  function temperatureLabel() {
    if (record !== undefined && record.temperature.record != null) {
      return record.temperature.record.degreeCelsius.toString();
    } else {
      return "0";
    }
  }
  function bloodpressureLabel() {
    if (record !== undefined && record.bloodPressure.record != null) {
      return (
        record.bloodPressure.record.systolicBloodPressure +
        "/" +
        record.bloodPressure.record.diastolicBloodPressure
      );
    } else {
      return "0";
    }
  }

  function heartRateLabel() {
    if (record !== undefined && record.heartRate.record != null) {
      return record.heartRate.record.pulse.toString();
    } else {
      return "0";
    }
  }

  function bloodOxygenTimeLabel() {
    if (record !== undefined && record.bloodOxygen.record != null) {
      const date = moment(
        record.bloodOxygen.record.monitorDateTime,
        "YYYY-MM-DD'T'hh:mm:ss'Z'"
      );
      return moment(date).format("YYYY-MM-DD hh:mm");
    } else {
      return "N.A";
    }
  }

  function temperatureTimeLabel() {
    if (record !== undefined && record.temperature.record != null) {
      const date = moment(
        record.temperature.record?.monitorDateTime,
        "YYYY-MM-DD'T'hh:mm:ss'Z'"
      );
      return moment(date).format("YYYY-MM-DD hh:mm");
    } else {
      return "N.A";
    }
  }

  function bloodpressureTimeLabel() {
    if (record !== undefined && record.bloodPressure.record != null) {
      const date = moment(
        record.bloodPressure.record.monitorDateTime,
        "YYYY-MM-DD'T'hh:mm:ss'Z'"
      );
      return moment(date).format("YYYY-MM-DD hh:mm");
    } else {
      return "N.A";
    }
  }
  function heartRateTimeLabel() {
    if (record !== undefined && record.heartRate.record != null) {
      const date = moment(
        record.heartRate.record.monitorDateTime,
        "YYYY-MM-DD'T'hh:mm:ss'Z'"
      );
      return moment(date).format("YYYY-MM-DD hh:mm");
    } else {
      return "N.A";
    }
  }

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <DeviceGridDiv>
      <div className="sticky w-292px h-60px flex">
        <img alt="" className="absolute" src={BackgroundImage} />
        <div className=" z-50 flex">
          <img
            alt=""
            className="ml-6 my-auto"
            src={props.userGender === "Male" ? Male : Female}
          />
          <div className="my-auto ml-5">
            <div className="text-bold text-white">
              {(props.index + 1).toString().padStart(3, "0")}
            </div>
            <div className="text-normal text-white">{props.userName}</div>
          </div>
        </div>
      </div>
      {record === undefined ? (
        <>
          <div className="w-full flex">
            <ClipLoader
              color="#36D7B7"
              css={override}
              loading={isLoadingRecord}
              size={35}
            />
          </div>
        </>
      ) : (
        <>
          {isExpanded ? (
            <>
              <div className="px-20px pt-9px pb-12px space-y-1.5">
                <DeviceDetailRow
                  image={Temperature}
                  name={temperatureTitle()}
                  value={temperatureLabel()}
                  monitorTime={temperatureTimeLabel()}
                />
                <DeviceDetailRow
                  image={HeartRate}
                  name={heartRateTitle()}
                  value={heartRateLabel()}
                  monitorTime={heartRateTimeLabel()}
                />
                <DeviceDetailRow
                  image={BloodPressureImage}
                  name={bloodPressureTitle()}
                  value={bloodpressureLabel()}
                  monitorTime={bloodpressureTimeLabel()}
                />
                <DeviceDetailRow
                  image={BloodOxygen}
                  name={bloodOxygenTitle()}
                  value={bloodOxygenLabel()}
                  monitorTime={bloodOxygenTimeLabel()}
                />
              </div>
            </>
          ) : (
            <>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <DataCard>
                        <img
                          alt=""
                          className="m-auto w-7 h-7"
                          src={Temperature}
                        />
                        <DataCardInput
                          readOnly={true}
                          value={temperatureLabel()}
                        />
                      </DataCard>
                    </td>
                    <td>
                      <DataCard>
                        <img
                          alt=""
                          className="m-auto w-7 h-7"
                          src={HeartRate}
                        />
                        <DataCardInput
                          readOnly={true}
                          value={heartRateLabel()}
                        />
                      </DataCard>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <DataCard>
                        <img
                          alt=""
                          className="m-auto w-7 h-7"
                          src={BloodPressureImage}
                        />
                        <DataCardInput
                          readOnly={true}
                          value={bloodpressureLabel()}
                        />
                      </DataCard>
                    </td>
                    <td>
                      <DataCard>
                        <img
                          alt=""
                          className="m-auto w-7 h-7"
                          src={BloodOxygen}
                        />
                        <DataCardInput
                          readOnly={true}
                          value={bloodOxygenLabel()}
                        />
                      </DataCard>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <DataCard>
                        <img
                          alt=""
                          className="m-auto w-7 h-7"
                          src={BloodGlucose}
                        />
                        <DataCardInput readOnly={true} value="N.A" />
                      </DataCard>
                    </td>
                    <td>
                      <DataCardAction>
                        <DataCardButton>
                          <img alt="" className="m-auto" src={ECG} />
                        </DataCardButton>
                        <DataCardButton>
                          <img alt="" className="m-auto" src={Area} />
                        </DataCardButton>
                        <DataCardButton>
                          <img alt="" className="m-auto" src={Stop} />
                        </DataCardButton>
                      </DataCardAction>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </>
      )}

      <div className="w-100 h-7 mt-1">
        <img
          alt=""
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          className="mx-auto"
          src={Arrow}
          style={{
            transform: isExpanded ? "rotate(0.5turn)" : "rotate(1turn)",
          }}
        />
      </div>
    </DeviceGridDiv>
  );
};

export default DeviceGrid;
