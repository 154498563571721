import React from "react";
import styled from "styled-components";
import SelectedBackground from "../../assets/bg_landing_selected.png";

interface Props {
  selectedIcon: any;
  icon: any;
  title: string;
  isSelected: boolean;
  onClick?: () => void;
}

const NavigationBarDiv = styled.div`
  width: 185px;
  height: 73px;
  display: flex;
`;

const NavigationBarImg = styled.img`
  width: 185px !important;
  height: 73px !important;
  margin: 0px !important;
`;

const NavigationBarButtonImg = styled.img`
  position: absolute;
  margin-top: 22px;
  margin-left: 22px;
  width: 30px;
  height: 30px;
`;

const NavigationBarAlterButtonImg = styled.img`
  position: relative;
  margin-top: 10px;
  margin-left: 22.55px;
  width: 30px;
  height: 30px;
`;

const NavigationBarButtonDiv = styled.div`
  background: #ffffff;
  box-shadow: 3px 3px 5px rgba(13, 39, 80, 0.498039);
  border-radius: 10px;
  width: 156px;
  height: 55px;
  margin-left: 9px;
  margin-right: 9px;
  margin-top: 10px;
  display: inline-flex;
  cursor: pointer;
`;

const NavigationBarButtonTitle = styled.div`
  position: absolute;
  width: 100.45px;
  margin-left: 52.55px;
  height: 73px;
  line-height: 73px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
`;

const NavigationBarAlterButtonTitle = styled.div`
  margin-left: 10px;
  margin-right: 22.55px;
  height: 55px;
  line-height: 55px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #214797;
`;

const NavigationBarButton = (props: Props) => {
  return (
    <div>
      {props.isSelected ? (
        <>
          <NavigationBarDiv>
            <NavigationBarImg src={SelectedBackground} />
            <NavigationBarButtonImg src={props.selectedIcon} />
            <NavigationBarButtonTitle>
              <p>{props.title}</p>
            </NavigationBarButtonTitle>
          </NavigationBarDiv>
        </>
      ) : (
        <>
          <NavigationBarButtonDiv
            onClick={() => {
              props.onClick && props.onClick();
            }}
          >
            <NavigationBarAlterButtonImg src={props.icon} />
            <NavigationBarAlterButtonTitle>
              <p>{props.title}</p>
            </NavigationBarAlterButtonTitle>
          </NavigationBarButtonDiv>
        </>
      )}
    </div>
  );
};

export default NavigationBarButton;
