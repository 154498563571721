import React, { Component, useState, useEffect } from "react";
import Props, { Device, User } from "../Types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  width: 400px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 30px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface LoginProps {
  onClick: (username: string, password: string) => void;
}

const Login = (props: LoginProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div className="w-screen h-screen absolute left-0 top-0 bg-black/50 z-50">
      <Container>
        <div className="mx-5 pt-5">
          Username
          <br />
          <input
            className="border-2 rounded-lg"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
        </div>
        <div className="mx-5 pt-5">
          Password
          <br />
          <input
            className="border-2 rounded-lg"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </div>
        <div className="w-full flex justify-center">
          <button
            className="border-2 rounded-lg px-10 py-3 mt-5"
            onClick={() => {
              props.onClick(username, password);
            }}
          >
            Login
          </button>
        </div>
      </Container>
    </div>
  );
};

export default Login;
