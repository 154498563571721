interface Props {}

export interface ATGPagingResult {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  result?: [any];
}

export interface ATGResponse {
  result: any;
  statusCode: number;
  errorMessage?: string;
}

export interface UserOverrallHealthRecord {
  bloodPressure: BloodPressure;
  heartRate: HeartRate;
  temperature: Temperature;
  bloodOxygen: BloodOxygen;
}

export interface BloodPressure {
  record?: BloodPressureRecord;
  standard: BloodPressureStandard;
}

export interface HeartRate {
  record?: HeartRateRecord;
  standard: CommonStandard;
}

export interface Temperature {
  record?: TemperatureRecord;
  standard: CommonStandard;
}

export interface BloodOxygen {
  record?: BloodOxygenRecord;
  standard: CommonStandard;
}

export interface BloodGlucose {
  record?: BloodGlucoseRecord;
  standard: CommonStandard;
}

interface CommonRecord {
  id: string;
  userId: string;
  monitorDateTime: string;
}

interface BloodPressureRecord extends CommonRecord {
  systolicBloodPressure: number;
  diastolicBloodPressure: number;
}

interface HeartRateRecord extends CommonRecord {
  pulse: number;
}

interface TemperatureRecord extends CommonRecord {
  degreeCelsius: number;
}

interface BloodOxygenRecord extends CommonRecord {
  spO2: number;
}

interface BloodGlucoseRecord extends CommonRecord {
  millimolesPerLitre: number;
}

interface BloodPressureStandard {
  id: string;
  name: string;
  high: BloodPressureData;
  normal: BloodPressureData;
  low: BloodPressureData;
  exception: CommonException;
}

interface CommonStandard {
  id: string;
  name: string;
  high: CommonStandardData;
  normal: CommonStandardData;
  low: CommonStandardData;
  exception: CommonException;
}

interface CommonException {
  color: string;
}

interface BloodPressureData extends CommonException {
  systolicBloodPressure: number;
  diastolicBloodPressure: number;
}

interface CommonStandardData extends CommonException {
  value: number;
}

export interface Device {
  id: string;
  name: string;
  productName: string;
  manufactoryName: string;
  status: string;
  productAppearances?: [ProductAppearance];
  enable: boolean;
}

export interface ProductAppearance {
  name: string;
  image: string;
}

export interface User {
  id: string;
  name: string;
  gender: string;
  enable: boolean;
}

export interface HealthAlert {
  id: string;
  userId: string;
  userName: string;
  alertLevel: AlertLevel;
  recordType: RecordType;
  solved: boolean;
}

export enum AlertLevel {
  None = "None",
  High = "High",
  Low = "Low",
  Exception = "Exception",
}

export enum RecordType {
  None = "None",
  BloodPressure = "BloodPressure",
  BloodGlucose = "BloodGlucose",
  ECG = "ECG",
  Step = "Step",
  HeartRate = "HeartRate",
  Sleep = "Sleep",
  Teeth = "Teeth",
  Temperature = "Temperature",
  BloodOxygen = "BloodOxygen",
}

export interface HealthAlertDetails {
  id: string;
  userId: string;
  level: AlertLevel;
  recordType: RecordType;
}

export default Props;
