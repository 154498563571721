import React, { Component, useState, useEffect } from "react";
import Props, { Device } from "../../Types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Image from "../../assets/icon_bo.png";

interface DeviceRowProps {
  device: Device;
  onClick: () => void;
}

const ShwdowedBox = styled.div`
  width: 70%;
  height: 45px;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(13, 39, 80, 0.298039), -2px -2px 5px #cbd4e2;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
`;

const Title = styled.div`
  margin-left: 17px;
  margin-top: auto;
  margin-bottom: auto;

  flex-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 72px);
  overflow: hidden;

  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #3c3c3c;
`;

const DeviceRow = (props: DeviceRowProps) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="flex w-full h-11">
      <ShwdowedBox className="grow-none">
        <img alt="" className="w-7 h-7 mt-2 ml-4" src={Image} />
        <Title>{props.device.name}</Title>
      </ShwdowedBox>
      <div
        className="h-11 ml-2 leading-11 text-center"
        style={{ flexGrow: 1 }}
        onClick={() => {
          props.onClick();
        }}
      >
        {props.device.enable ? (
          <>
            <div className="device-disabled">{t("disable")}</div>
          </>
        ) : (
          <>
            <div className="device-enabled">{t("enable")}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeviceRow;
