import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../src/i18n/en.json";
import hk from "../src/i18n/zh-HK.json";
import cn from "../src/i18n/zh-CN.json";
import axios from "axios";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en,
  },
  "zh-HK": {
    translation: hk,
  },
  "zh-CN": {
    translation: cn,
  },
};

let locale = "en";
axios.defaults.headers.common["Accept-Language"] = locale;
if (localStorage.getItem("footmark-lang")) {
  locale = localStorage.getItem("footmark-lang");
  axios.defaults.headers.common["Accept-Language"] = locale;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: locale, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export function changeLanguage(lang) {
  i18n.changeLanguage(lang);
  axios.defaults.headers.common["Accept-Language"] = lang;
  localStorage.setItem("footmark-lang", lang);
  return "";
}

export default i18n;
