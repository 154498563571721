import React, { Component, useState, useEffect } from "react";
import Props, { AlertLevel, HealthAlert, RecordType } from "../../Types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ProfileManager from "../../plugins/ProfileManager";
import Bell from "../../assets/icon_bell.png";
import RedTemperature from "../../assets/icon_user_temperature_red.png";
import RedHeartRate from "../../assets/icon_user_heartrate_red.png";
import RedBloodPressureImage from "../../assets/icon_user_bp_red.png";
import RedBloodOxygen from "../../assets/icon_user_o2_red.png";
import YellowTemperature from "../../assets/icon_user_temperature_yellow.png";
import YellowHeartRate from "../../assets/icon_user_heartrate_yellow.png";
import YellowBloodPressureImage from "../../assets/icon_user_bp_yellow.png";
import YellowBloodOxygen from "../../assets/icon_user_o2_yellow.png";

interface AlertRowProps {
  alert: HealthAlert;
  dismissAlert: () => void;
}

const AlertRow = (props: AlertRowProps) => {
  const { t } = useTranslation();
  const getColor = () => {
    const color = ProfileManager.getInstance().getColorBy(
      props.alert.userId,
      props.alert.recordType,
      props.alert.alertLevel
    );
    return color;
  };

  const getTitle = () => {
    var key = "";
    if (props.alert.recordType === RecordType.BloodPressure) {
      key = "bloodPressure.title";
    } else if (props.alert.recordType === RecordType.HeartRate) {
      key = "heartRate.title";
    } else if (props.alert.recordType === RecordType.Temperature) {
      key = "temperature.title";
    } else if (props.alert.recordType === RecordType.BloodOxygen) {
      key = "bloodOxygen.title";
    }
    return t(key);
  };

  const getImage = () => {
    if (
      props.alert.alertLevel === AlertLevel.High ||
      props.alert.alertLevel === AlertLevel.Low
    ) {
      if (props.alert.recordType === RecordType.BloodPressure) {
        return YellowBloodPressureImage;
      } else if (props.alert.recordType === RecordType.HeartRate) {
        return YellowHeartRate;
      } else if (props.alert.recordType === RecordType.Temperature) {
        return YellowTemperature;
      } else if (props.alert.recordType === RecordType.BloodOxygen) {
        return YellowBloodOxygen;
      }
    } else {
      if (props.alert.recordType === RecordType.BloodPressure) {
        return RedBloodPressureImage;
      } else if (props.alert.recordType === RecordType.HeartRate) {
        return RedHeartRate;
      } else if (props.alert.recordType === RecordType.Temperature) {
        return RedTemperature;
      } else if (props.alert.recordType === RecordType.BloodOxygen) {
        return RedBloodOxygen;
      }
    }
  };

  return (
    <div className="flex relative h-16">
      <div
        className="h-16 rounded-lg border-4 w-2/5 flex"
        style={{ borderColor: getColor() }}
      >
        <img src={getImage()} alt="" />
        <div className="m-auto text-center">{getTitle()}</div>
      </div>
      <div className="w-2/5 text-center self-center">
        {props.alert.userName}
      </div>
      <div
        className="w-1/5 self-center"
        onClick={() => {
          props.dismissAlert();
        }}
      >
        <img alt="" src={Bell} />
      </div>
    </div>
  );
};

export default AlertRow;
