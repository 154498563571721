import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import AuthorizationHub from "./plugins/AuthorizationHub";
import IoTHub from "./plugins/IoT-Hub";

AuthorizationHub.getInstance().initEndpoint({
  endpoint: "https://atg-easy.authorization-hub.j1.business",
  subscription: "NzI4NjdiMTEtYjc5OC00YWU2LWEyNGItZjI5MDBkMzczZGJj",
  solutionId: "gyDaEzsdm0u_MT9LwAcsgA",
});
console.log(AuthorizationHub.getInstance().endpoint);

IoTHub.getInstance().initEndpoint({
  endpoint: "https://debug1.iot-hub.j1.business",
  subscription: "ZmM1OGVkMDktMmQ0MC00OWFjLThiNDYtOGY0YmMxZmVlNjg5",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
