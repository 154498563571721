import axios from "axios";
import i18n from "i18next";
import AuthorizationHub from "./AuthorizationHub";

export interface IoTHubProp {
  endpoint: string;
  subscription: string;
}

class IoTHub {
  private static instance: IoTHub;
  request: any;
  endpoint!: string;
  subscription!: string;

  private constructor() {}

  static getInstance() {
    if (!IoTHub.instance) {
      IoTHub.instance = new IoTHub();
    }
    return IoTHub.instance;
  }

  initEndpoint(props: IoTHubProp) {
    this.endpoint = props.endpoint;
    this.subscription = props.subscription;
    this.request = axios.create({
      baseURL: this.endpoint,
      headers: { Subscription: this.subscription },
    });
  }

  listAllDevice(page = 1, pageSize = 10) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`api/Frontend/Device?page=${page}&pageSize=${pageSize}`, {
              headers: {
                Authorization: token,
                "Accept-Language": i18n.language,
              },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listDevice(productAppearance: string, page = 1, pageSize = 10) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/Device?Enable=true&Page=${page}&PageSize=${pageSize}&productAppearance=${productAppearance}`,
              {
                headers: {
                  Authorization: token,
                  "Accept-Language": i18n.language,
                },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  enableDevice(deviceId: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(
              `api/Frontend/Device/${deviceId}/Enable`,
              {},
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  disableDevice(deviceId: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(
              `api/Frontend/Device/${deviceId}/Disable`,
              {},
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  overallRecord(userId?: string, isDesc = true) {
    const _this = this;
    _this.request = axios.create({
      baseURL: this.endpoint,
      headers: {
        Subscription: this.subscription,
      },
    });
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/OverallHealthRecord?UserId=${userId}&isDesc=${isDesc}`,
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  listSession(userId = null, page = 1, pageSize = 10) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/Session?userId=${userId}&Type=Flow&Page=${page}&PageSize=${pageSize}`,
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  listAlert() {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`/api/Frontend/HealthAlert?Page=1&PageSize=200&Solved=false`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  getAlertDetailsBy(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`/api/Frontend/HealthAlert/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  dismissAlert(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(
              `/api/Frontend/HealthAlert/${id}/Solved`,
              {},
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  listFlow(deviceId = null, flowType = []) {
    var flows = flowType.map((n) => `FlowType=${n}`).join("&");
    const _this = this;

    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`api/Frontend/Flow?DeviceId=${deviceId}&${flows}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  startFlow(userId = null, deviceId = null, flowId = null, receiverId = null) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .post(
              `api/Frontend/Flow`,
              {
                userId: userId,
                deviceId: deviceId,
                flowId: flowId,
                receiverId: receiverId,
              },
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  listTemperature(userId = null, page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/TemperatureRecord?page=${page}&pageSize=${pageSize}&isDesc=${isDesc}&userId=${userId}`,
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listBloodOxygen(userId = null, page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/BloodOxygenRecord?page=${page}&pageSize=${pageSize}&isDesc=${isDesc}&userId=${userId}`,
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listBloodPressure(userId = null, page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/BloodPressureRecord?page=${page}&pageSize=${pageSize}&isDesc=${isDesc}&userId=${userId}`,
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listHeartRate(userId = null, page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/HeartRateRecord?page=${page}&pageSize=${pageSize}&isDesc=${isDesc}&userId=${userId}`,
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listBloodGlucose(userId = null, page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/BloodGlucoseRecord?page=${page}&pageSize=${pageSize}&isDesc=${isDesc}&userId=${userId}`,
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listUser({ page = 1, pageSize = 10, isDesc = false, name = "" }) {
    const _this = this;
    _this.request = axios.create({
      baseURL: this.endpoint,
      headers: {
        Subscription: this.subscription,
      },
    });
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/User?Enable=true&Page=${page}&PageSize=${pageSize}&isDesc=${isDesc}&name=${name}`,
              {
                headers: { Authorization: token },
              }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  detailUser(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`api/Frontend/User/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  saveUser(id: string, content: any) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(`api/Frontend/User/${id}`, content, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  createUser(content: any) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .post(`api/Frontend/User`, content, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  enableUser(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(
              `api/Frontend/User/${id}/Enable`,
              {},
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  prepareCreateReceiver() {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .post(
              `api/Frontend/Receiver/QRCode`,
              {},
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  createReceiver(verifyCode: string, name: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .post(
              `api/Frontend/Receiver`,
              {
                verifyCode: verifyCode,
                name: name,
              },
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  removeReceiver(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .delete(`api/Frontend/Receiver/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  enableReceiver(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(
              `api/Frontend/Receiver/${id}/Enable`,
              {},
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listBloodPressureStandardProfile(page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/UserStandardBloodPressureProfile?Enable=true&Page=${page}&PageSize=${pageSize}&isDesc=${isDesc}`,
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  detailBloodPressureStandardProfile(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`api/Frontend/UserStandardBloodPressureProfile/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  saveBloodPressureStandardProfile(id: string, content: any) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(
              `api/Frontend/UserStandardBloodPressureProfile/${id}`,
              content,
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  deleteBloodPressureStandardProfile(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .delete(`api/Frontend/UserStandardBloodPressureProfile/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  listHeartRateStandardProfile(page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/UserStandardHeartRateProfile?Enable=true&Page=${page}&PageSize=${pageSize}&isDesc=${isDesc}`,
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  detailHeartRateStandardProfile(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`api/Frontend/UserStandardHeartRateProfile/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  saveHeartRateStandardProfile(id: string, content: any) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(`api/Frontend/UserStandardHeartRateProfile/${id}`, content, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  deleteHeartRateStandardProfile(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .delete(`api/Frontend/UserStandardHeartRateProfile/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listBloodGlucoseStandardProfile(page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/UserStandardBloodGlucoseProfile?Enable=true&Page=${page}&PageSize=${pageSize}&isDesc=${isDesc}`,
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  detailBloodGlucoseStandardProfile(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`api/Frontend/UserStandardBloodGlucoseProfile/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  saveBloodGlucoseStandardProfile(id: string, content: any) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(
              `api/Frontend/UserStandardBloodGlucoseProfile/${id}`,
              content,
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listBloodOxygenStandardProfile(page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/UserStandardBloodOxygenProfile?Enable=true&Page=${page}&PageSize=${pageSize}&isDesc=${isDesc}`,
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  detailBloodOxygenStandardProfile(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`api/Frontend/UserStandardBloodOxygenProfile/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  saveBloodOxygenStandardProfile(id: string, content: any) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(`api/Frontend/UserStandardBloodOxygenProfile/${id}`, content, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  deleteBloodOxygenStandardProfile(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .delete(`api/Frontend/UserStandardBloodOxygenProfile/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  listTemperatureStandardProfile(page = 1, pageSize = 10, isDesc = true) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(
              `api/Frontend/UserStandardTemperatureProfile?Enable=true&Page=${page}&PageSize=${pageSize}&isDesc=${isDesc}`,
              { headers: { Authorization: token } }
            )
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
  detailTemperatureStandardProfile(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .get(`api/Frontend/UserStandardTemperatureProfile/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  saveTemperatureStandardProfile(id: string, content: any) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .put(`api/Frontend/UserStandardTemperatureProfile/${id}`, content, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }

  deleteTemperatureStandardProfile(id: string) {
    const _this = this;
    return new Promise((resolve, reject) => {
      AuthorizationHub.getInstance()
        .requestAuthorization()
        .then(function (token) {
          _this.request
            .delete(`api/Frontend/UserStandardTemperatureProfile/${id}`, {
              headers: { Authorization: token },
            })
            .then(function (response: any) {
              resolve(response.data.result);
            })
            .catch(function (reason: any) {
              reject(reason.response.data);
            });
        });
    });
  }
}

export default IoTHub;
