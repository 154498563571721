import { UserOverrallHealthRecord, RecordType, AlertLevel } from "../Types";

class ProfileManager {
  private static instance: ProfileManager;
  private records: { [id: string]: UserOverrallHealthRecord } = {};

  private constructor() {}

  static getInstance() {
    if (!ProfileManager.instance) {
      ProfileManager.instance = new ProfileManager();
    }
    return ProfileManager.instance;
  }

  store(id: string, record: UserOverrallHealthRecord) {
    this.records[id] = record;
  }

  getColorBy(id: string, type: RecordType, level: AlertLevel) {
    if (id in this.records) {
      const record = this.records[id];
      var standard;
      if (type === RecordType.BloodPressure) {
        standard = record.bloodPressure.standard;
        if (standard === undefined) {
          if (level === AlertLevel.Low) {
            return "FF8800";
          }
          return "#FF0000";
        } else {
          if (level === AlertLevel.High) {
            return standard.high.color;
          } else if (level === AlertLevel.Low) {
            return standard.low.color;
          } else {
            return standard.exception.color;
          }
        }
      } else {
        if (type === RecordType.BloodOxygen) {
          standard = record.bloodOxygen.standard;
        } else if (type === RecordType.HeartRate) {
          standard = record.heartRate.standard;
        } else if (type === RecordType.Temperature) {
          standard = record.temperature.standard;
        }
        if (standard === undefined) {
          if (level === AlertLevel.Low) {
            return "FF8800";
          }
          return "#FF0000";
        } else {
          if (level === AlertLevel.High) {
            return standard.high.color;
          } else if (level === AlertLevel.Low) {
            return standard.low.color;
          } else {
            return standard.exception.color;
          }
        }
      }
    }
    if (level === AlertLevel.Low) {
      return "FF8800";
    }
    return "#FF0000";
  }
}

export default ProfileManager;
