import { sha512 } from "hash-wasm";
import SecureLS from "secure-ls";
import axios from "axios";

interface AuthorizationHubProp {
  endpoint: string;
  subscription: string;
  solutionId: string;
}

interface AuthorizationHubSetting {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expire_date: number;
}

class AuthorizationHub {
  private static instance: AuthorizationHub;
  request: any;
  refreshingToken = false;
  refreshingTokenCallback!: { (): void }[];
  endpoint!: string;
  subscription!: string;
  solutionId!: string;
  setting!: AuthorizationHubSetting;

  private constructor() {}

  static getInstance() {
    if (!AuthorizationHub.instance) {
      AuthorizationHub.instance = new AuthorizationHub();
    }
    return AuthorizationHub.instance;
  }

  initEndpoint(props: AuthorizationHubProp) {
    this.endpoint = props.endpoint;
    this.subscription = props.subscription;
    this.solutionId = props.solutionId;
    this.request = axios.create({
      baseURL: this.endpoint,
      headers: { Subscription: this.subscription },
    });
    this.setting = this.restoreSetting();
  }

  restoreSetting() {
    var ls = new SecureLS();
    var setting = ls.get("AuthorizationHub");
    if (setting) {
      var json = JSON.parse(setting);

      return {
        access_token: json.access_token,
        token_type: json.token_type,
        refresh_token: json.refresh_token,
        expire_date: json.expire_date,
      };
    } else {
      return {
        access_token: null,
        token_type: null,
        refresh_token: null,
        expire_date: null,
      };
    }
  }

  async checkUsernameAvailable(username: string) {
    return new Promise((resolve) => {
      this.request
        .get(`api/Frontend/SignUp/${username}`)
        .then(function (response: any) {
          resolve(response.data.result);
        });
    });
  }

  requestAuthorization() {
    return new Promise((resolve, reject) => {
      if (this.setting.access_token != null) {
        var accessToken = this.setting.access_token;
        var exDate = this.setting.expire_date;
        var now = new Date();
        if (exDate - 1000 > now.getTime()) {
          if (!accessToken) {
            reject("onUnauthorized");
          } else {
            resolve(this.setting.token_type + " " + this.setting.access_token);
          }
        } else {
          this.refreshToken()
            .then(function (token) {
              resolve(token);
            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            });
        }
      } else {
        if (this.setting.access_token == null) {
          reject("onUnauthorized");
        } else {
          this.requestAuthorization()
            .then(function (token) {
              resolve(token);
            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            });
        }
      }
    });
  }

  refreshToken() {
    console.log("refreshToken");

    const _this = this;
    if (!this.refreshingToken) {
      this.refreshingToken = true;
      return new Promise((resolve, reject) => {
        this.request
          .post(
            `api/Frontend/Token`,
            { refreshToken: this.setting.refresh_token },
            {
              headers: {
                Authorization: `${this.setting.token_type} ${this.setting.access_token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response: any) {
            console.log(response);
            if (response.status === 200) {
              var result = response.data.result;

              var expireIn = result.expires_in;
              var now = new Date();
              now.setSeconds(now.getSeconds() + expireIn);

              _this.setting = {
                access_token: result.access_token,
                token_type: result.token_type,
                refresh_token: result.refresh_token,
                expire_date: now.getTime(),
              };

              var ls = new SecureLS();
              ls.set("AuthorizationHub", JSON.stringify(_this.setting));

              resolve(
                _this.setting.token_type + " " + _this.setting.access_token
              );

              _this.refreshingToken = false;

              for (var index in _this.refreshingTokenCallback) {
                var callback = _this.refreshingTokenCallback[index];
                if (callback) {
                  callback();
                }
              }
              _this.refreshingTokenCallback = [];
            } else {
              reject(response.data);
            }
          });
      });
    } else {
      return new Promise((resolve) => {
        console.log("pending");
        this.refreshingTokenCallback.push(function () {
          console.log("finish");
          resolve(_this.setting.token_type + " " + _this.setting.access_token);
        });
      });
    }
  }

  signIn(username: string, password: string) {
    var _this = this;

    return new Promise((resolve, reject) => {
      sha512(password).then(function (hash) {
        _this.request
          .post(`api/Frontend/SignIn/${_this.solutionId}`, {
            userName: username,
            passwordHash: hash,
          })
          .then(function (response: any) {
            if (response.status === 200) {
              var result = response.data.result;

              var expireIn = result.expires_in;

              var now = new Date();
              now.setSeconds(now.getSeconds() + expireIn);

              _this.setting = {
                access_token: result.access_token,
                token_type: result.token_type,
                refresh_token: result.refresh_token,
                expire_date: now.getTime(),
              };

              var ls = new SecureLS();
              ls.set("AuthorizationHub", JSON.stringify(_this.setting));

              resolve(
                _this.setting.token_type + " " + _this.setting.access_token
              );
            } else {
              reject(response.data);
            }
          })
          .catch(function (reason: any) {
            reject(reason.response.data);
          });
      });
    });
  }

  signOut() {
    var ls = new SecureLS();
    ls.remove("AuthorizationHub");
  }
}

export default AuthorizationHub;
