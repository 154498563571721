import { render } from "@testing-library/react";
import React, { Component, useState, useEffect, useRef } from "react";
import Props from "../../Types";
import styled from "styled-components";
import NavigationBarButton from "./NavigationBarButton";
import { useTranslation } from "react-i18next";
import moment from "moment";

import ClinicOne from "../../assets/icon_clinic_one.png";
import ArrowDown from "../../assets/icon_gray_arrow_down.png";
import Clear from "../../assets/icon_close.png";
import SelectedMonitor from "../../assets/icon_monitor.png";
import Monitor from "../../assets/icon_monitor_blue.png";
import SelectedHistory from "../../assets/icon_history.png";
import History from "../../assets/icon_history_blue.png";
import SelectedAnalysis from "../../assets/icon_analysis.png";
import Analysis from "../../assets/icon_analysis_blue.png";
import { changeLanguage } from "../../i18n";
const NavigationBarShadowDiv = styled.div`
  box-shadow: 3px 3px 5px rgba(13, 39, 80, 0.498039);
  transform-style: preserve-3d;
`;

const ShadowedTextField = styled.input`
  box-shadow: 3px 3px 5px rgba(13, 39, 80, 0.498039);
  border-radius: 30px;
  border: 0px !important;
  &:focus {
    outline: none;
  }
`;

const HoverHiddenDiv = styled.div`
  cursor: pointer;
  overflow: hidden;
  &:hover {
    overflow: visible;
  }
`;

interface NavigationBarProps {
  handleSearch: (keyword: string) => void;
  clean: () => void;
  signout: () => void;
}

const NavigationBar = (props: NavigationBarProps) => {
  const [selectedId, setSelectedId] = useState("Monitor");
  const [keyword, setKeyword] = useState("");
  const { t, i18n } = useTranslation();
  const optionsLang = [
    { text: "繁體中文", value: "zh-HK" },
    { text: "简体中文", value: "zh-CN" },
    { text: "English", value: "en" },
  ];
  const [date, setDate] = useState(new Date());
  const keywordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <NavigationBarShadowDiv className="w-100 h-20 flex items-stretch">
      <div className="flex items-stretch">
        <img
          alt=""
          className="py-5 pl-5"
          src={ClinicOne}
          onClick={() => {
            console.log("clicked");
            props.signout();
          }}
        />
        <img alt="" className="w-6 h-6 self-center ml-2.5" src={ArrowDown} />
      </div>
      <div className="ml-16 w-1/5 h-7 self-center relative flex">
        <div className=" flex w-full">
          <ShadowedTextField
            className={`pr-7 pl-2.5 ${selectedId !== "Monitor" && "hidden"}`}
            type="text"
            name="textInput"
            ref={keywordRef}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.handleSearch(keyword);
              }
            }}
          />
        </div>
        <div
          onClick={() => {
            if (keywordRef.current) {
              keywordRef.current.value = "";
            }
            setKeyword("");
            props.clean();
          }}
          className={`absolute flex right-2 w-7 h-7 ${
            keyword.length === 0 && "hidden"
          }`}
        >
          <img alt="" className="w-1/2 h-1/2 self-center mx-auto" src={Clear} />
        </div>
      </div>
      <div className="flex ml-11">
        <NavigationBarButton
          selectedIcon={SelectedMonitor}
          icon={Monitor}
          title={t("monitor.title")}
          isSelected={selectedId === "Monitor"}
          onClick={() => {
            setSelectedId("Monitor");
          }}
        ></NavigationBarButton>
        <NavigationBarButton
          selectedIcon={SelectedMonitor}
          icon={Monitor}
          title={t("history.title")}
          isSelected={selectedId === "History"}
          onClick={() => {
            setSelectedId("History");
          }}
        ></NavigationBarButton>
        <NavigationBarButton
          selectedIcon={SelectedMonitor}
          icon={Monitor}
          title={t("analysis.title")}
          isSelected={selectedId === "Analysis"}
          onClick={() => {
            setSelectedId("Analysis");
          }}
        ></NavigationBarButton>
      </div>
      <HoverHiddenDiv className="ml-auto mr-2.5">
        <div className=" h-full flex">
          <div className="self-center ml-auto">{t("language")}</div>
        </div>
        <div className="bg-white rounded-b-lg shadow-lg overflow-hidden">
          {optionsLang.map((item, index) => {
            return (
              <div
                className="bg-white hover:bg-blue-500 hover:text-white"
                onClick={() => {
                  changeLanguage(item.value);
                }}
              >
                <div
                  style={{ height: "1px" }}
                  className={`bg-black ${index === 0 && "hidden"}`}
                />
                <div className="p-3">{item.text}</div>
              </div>
            );
          })}
        </div>
      </HoverHiddenDiv>
      <div className="text-base self-center text-right mr-7">
        <span>{moment(date).format("YYYY/MM/DD MMMM")}</span>
        <br />
        <span>{moment(date).format("hh:mm:ss")}</span>
      </div>
    </NavigationBarShadowDiv>
  );
};

export default NavigationBar;
